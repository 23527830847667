/**
 * 파 일 명  : braille.js
 * 변경이력
 * 2023. 06. 28. / 이성준 / 최초작성
 */

/**
 * @type {Readonly<{LIBLOUIS: string, DOT: string, AMEDIA: string}>}
 */
const brailleEngine = Object.freeze({
  DOT: "DOT",
  LIBLOUIS: "LIBLOUIS",
  AMEDIA: "AMEDIA",
});

// state
const state = () => ({
  kind: brailleEngine.DOT,
  language: 'english',
  grade: '2',
  rule: '',
  pin: 6,
});

// getters
const getters = {
  kind: (state) => {
    return state.kind;
  },
  language: (state) => {
    return state.language;
  },
  grade: (state) => {
    return state.grade;
  },
  rule: (state) => {
    return state.rule;
  },
  pin: (state) => {
    return state.pin;
  },
};

// mutations
const mutations = {
  setKind(state, kind) {
    state.kind = brailleEngine[kind];
  },
  setLanguage(state, language) {
    state.language = language;
  },
  setGrade(state, grade) {
    state.grade = grade;
  },
  setRule(state, rule) {
    state.rule = rule;
  },
  setPin(state, pin) {
    state.pin = pin;
  },
}

// actions
// const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  // actions,
  mutations
}
