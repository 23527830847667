<template>
  <router-link to="/">
    <img
      v-if="isNotKCHFUser"
      :src="dot.src"
      class="dot-logo"
      :alt="dot.alt"
      loading="lazy"
    />
    <template v-else>
      <img
        v-for="(image, i) in images"
        :key="i"
        :src="require(`@/assets/images/external/kchf/${image.src}`)"
        :alt="image.alt"
        loading="lazy"
      />
      &nbsp;
    </template>
  </router-link>
</template>

<script setup>
import { reactive, ref } from "vue";
import global from "global";

const isNotKCHFUser = ref(!global.isKCHFUser());

const dot = reactive({
  src: "/theme/responsive_dot/include/img/dot_logo.svg",
  alt: "Dot Incorporation Logo",
});
const images = reactive([
  {
    // 국가유산청 로고
    src: "cha.go.kr.jpg",
    alt: "국가유산청 로고",
  },
  {
    // 국가유산진흥원 로고
    src: "chf.or.kr.jpg",
    alt: "국가유산진흥원 로고",
  },
]);
</script>

<style scoped>
.dot-logo {
  width: 100px;
  height: 25px;
}
</style>
